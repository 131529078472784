import { fg } from '@atlassian/jira-feature-gating';
import {
	getIsAdmin,
	getIsNav4TenantOptIn,
	getIsNav4TenantOptOut,
	getIsNav4UserOptIn,
	getIsNav4UserOptOut,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-get-user-meta/src/index.tsx';

// We don't inline feature gates as our logic to show nav4 is not trivial and should not be duplicated.
// See https://hello.atlassian.net/wiki/spaces/JNR/pages/3699574590/Rollout+technical+design#Decision-points
export const getWillShowNav4ForGa = () => {
	// Later GA phases eclipse earlier phases.
	// For example, if phase 2 is on and the result is false, that should take precedence over phase 1 with a result of true.
	if (fg('jira_nav4_ga_phase-3')) {
		return true;
	}

	if (fg('jira_nav4_ga_phase-2')) {
		return !getIsNav4UserOptOut();
	}

	if (fg('jira_nav4_ga_phase-1-5')) {
		return getIsAdmin()
			? !getIsNav4UserOptOut()
			: !getIsNav4TenantOptOut() && !getIsNav4UserOptOut();
	}

	if (fg('jira_nav4_ga_phase-1')) {
		return getIsAdmin() ? getIsNav4UserOptIn() : getIsNav4TenantOptIn() && !getIsNav4UserOptOut();
	}

	if (fg('jira_nav4_ga_sandboxes')) {
		return getIsNav4UserOptIn();
	}

	return false;
};
